#header-bar {
  height: 3rem;
  background-color: white;
  width: 100%;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  align-items: center;

  #logo-section {
    width: 25%;
    flex-grow: 0;
    height: 100%;
    border-right: 1px solid rgb(224, 224, 224);
  }

  #name-section {
    width: 50%;
    flex-grow: 1;
    padding: 0 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  #menu-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 100%;
    border-left: 1px solid rgb(224, 224, 224);

    #banner-menu {
      cursor: pointer;
    }
  }

  #banner-logo {
    margin: 0 2.6rem;
    height: 95%;
  }
}

#dashboard-top-bar {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  background-color: white;
  column-gap: 1rem;
  border-radius: 0.5rem;
  height: 4.3rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.2rem 0.8rem;
  padding-left: 2rem;
  position: relative;

  #email-button,
  #search-button {
    cursor: pointer;
  }

  #initial-button {
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 2.5rem;
    border: none;
    background-color: #00adbb;
    box-shadow: rgba(0, 0, 0, 0.3) 0 0.1rem 0.4rem;
    color: white;
    font-size: 1.9rem;
    font-weight: 400;
    padding: 0;
    margin-left:0.4rem;
  }

  #top-bar-right {
    display: flex;
    align-items: center;
    height: 100%;
    column-gap: 0.5rem;
    position: absolute;
    right: 1rem;

    h3 {
      font-weight: 400;
      font-size: 1.2rem;
    }
  }
}