#login-page {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #e8e4e1;
  display: flex;
  align-items: center;
  position: relative;
  background-image: url("../../../public/backgroundv2.jpeg");
  background-size: auto 100%;
}
