#change-password-form {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    min-width: 30rem;
    height: 26rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1,
    h3 {
        text-align: center;
    }

    h1 {
        font-size: 1.7rem;
        font-weight: 500;
    }

    h2 {
        font-size: 1rem;
    }

    h3 {
        font-size: 0.9rem;
        font-weight: 400;
    }

    .form-row {
        height: 4rem;
        width: 100%;
        display: flex;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
    }
}