.image-holder {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: dashed 2px #00adbb;
  cursor: pointer;
  aspect-ratio: 1;
  width: 100%;
  box-sizing: border-box;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  h4 {
    text-align: center;
    color: grey;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.image-container {
  flex-grow: 1;
  width: 11rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.3rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}