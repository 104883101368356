#cases-page {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  padding: 3rem 6rem;
  box-sizing: border-box;
  position: relative;
  align-items: start;

  h1,
  h2 {
    margin: 0;
  }

  h1 {
    font-size: 1.4rem;
  }

  #dashboard-header-row {
    display: flex;
    width: 100%;
    max-width: 98rem;
    min-width: 20rem;
    justify-content: space-between;
    margin-bottom: 2rem;
    column-gap: 8rem;
    align-self: center;
    vertical-align: center;
    z-index: 1;
  }

  #add-new-case {
    float: left;
    display: flex;
    cursor: pointer;
    height: 3.2rem;
    width: 100%;
    max-width: 98rem;
    min-width: 20rem;
    align-items: center;
    column-gap: 0.2rem;
    align-self: Center;
    z-index: 2;

    #add-case-icon {
      color: grey;
    }

    p {
      font-size: 1.4rem;
      color: gray;
    }

    &:hover {
      p {
        color: black;
      }

      #add-case-icon {
        color: #ff0066;
      }
    }
  }

  #navbit-title {
    width: 16rem;
    height: 4.6rem;
  }

  #case-bar-title-wrapper {
    background-color: rgb(245, 245, 245);
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 1.5rem;
    height: 3rem;
    flex-shrink: 0;
    background-color: rgb(236, 236, 236);
    z-index: 1;
  }

  #case-bar-title {
    display: grid;
    width: calc(100% - 4rem);
    grid-template-columns: 7fr 2fr 4fr 5fr 5fr 3fr;
    align-items: center;
    cursor: default;
    height: 100%;

    h2 {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  #pagination-container {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
  }

  #cases-card {
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0.2rem 1rem;
    padding: 2rem;
    margin-bottom: 1rem;
    margin-top: 0.4rem;
    height: 38rem;
    display: flex;
    width: 100%;
    max-width: 98rem;
    min-width: 20rem;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    background-color: white;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    align-self: center;
    z-index: 1;
  }

  #case-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .row {
    display: flex;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    padding: 0 0.4rem;
    box-sizing: border-box;
    align-items: center;
  }
}

.loader-holder {
  width: 100%;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.case-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 52rem;
  background-color: rgb(236, 236, 236);
  justify-content: center;
}

.close-form-button {
  color: grey;
  cursor: pointer;
  position: absolute;
  right: 0.6rem;
  top: 0.6rem;

  &:hover {
    color: black;
  }
}

.misc-info  {
  display: flex;
  width: 100%;
  max-width: 98rem;
  min-width: 20rem;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: bottom;
  row-gap: 1rem;
  text-align: right;
  z-index: 1;
  color: grey;
  align-self: center;
}

#create-case-form,
#upload-templating-form {
  height: 47rem;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 3rem 2rem 3rem;
  box-sizing: border-box;
  row-gap: 1.5rem;
  z-index: 2;

  .field-holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 3.5rem;
    row-gap: 0.2rem;
    justify-content: space-between;

    h2 {
      font-size: 1rem;
      font-weight: 500;
    }

    &.less {
      width: 75%;
    }
  }
}

#edit-case-form{
  height: 36rem;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 3rem 2rem 3rem;
  box-sizing: border-box;
  row-gap: 1.5rem;
  z-index: 2;

  .field-holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 3.5rem;
    row-gap: 0.2rem;
    justify-content: space-between;

    h2 {
      font-size: 1rem;
      font-weight: 500;
    }

    &.less {
      width: 75%;
    }
  }
}


#white-logo{
  position: absolute;
  right:10rem;
  bottom:10rem;
  width:30rem;
  height: 30rem;
  z-index: 0;
  transform: rotate(-45deg);
}