#upload-templating-form {
    padding-left: 3rem;
    padding-right: 3rem;

    h1,
    h3 {
        text-align: center;
    }

    h1 {
        font-size: 1.7rem;
        font-weight: 500;
    }

    h3 {
        font-size: 0.9rem;
        font-weight: 400;
    }

    #upload-templating-divider {
        display: flex;
        justify-content: center;
        width: 100%;
        column-gap: 3rem;

        #upload-templating-left {
            width: 40%;
            display: flex;
            flex-direction: column;
            row-gap: 1.2rem;
        }

        #upload-templating-right {
            width: 60%;
            display: flex;
            flex-direction: column;
            row-gap: 1.2rem;

            .field-holder.tall {
                height: 20.4rem;
            }

            #templating-remarks {
                height: 100%;
                width: 100%;
                border-radius: 0.5rem;
            }

            #upload-templating-image-field {
                display: flex;
                flex-direction: column;
                align-items: start;
                row-gap: 0.4rem;

                h2 {
                    font-size: 1rem;
                }

                .image-holder {
                    width: 10rem;
                }
            }
        }
    }
}