.casebar {
  display: grid;
  height:4rem;
  width: calc(100% - 4rem);
  grid-template-columns: 7fr 2fr 4fr 5fr 5fr 3fr;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin: 0 4rem;

  // // for mobile screen
  // @media screen and (max-width: 660px) {
  //     grid-template-columns: 6fr 4fr;
  // }
  // for big screens
  @media screen and (min-width: 960px) {
      grid-template-columns: 7fr 2fr 4fr 5fr 5fr 3fr;
  }


  &:hover,
  &.clicked {
    font-weight: 700;

    .case-action {
      display: grid;
      visibility: visible;
    }
  }

  .hiddable {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .case-actions {
    visibility: hidden;
    cursor: pointer;
    width: 1.4rem;
    height: 1.4rem;
  }
}

.casebar+.casebar {
  border-top: solid 0.125rem rgb(200, 200, 200);
}

.disabled-item {
  color: red;
}