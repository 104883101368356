#annotation-page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  background-color: black;

  /* If you want to implement it in very old browser-versions */
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* The rule below is not implemented in browsers yet */
  -o-user-select: none;

  /* The rule below is implemented in most browsers by now */
  user-select: none;

  #annotation-steps {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
    justify-content: center;

    ul {
      list-style-type: none;
      padding: 0;
      li {
        text-align: start;
      }
    }

    #annotation-step-actions {
      display: flex;
      align-items: center;
      column-gap: 2rem;
      color: white;

      #annotation-reset {
        cursor: pointer;
        font-size: 2.4rem;
      }
    }
  }

  #annotate-reference {
    svg {
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    }
    circle.middle-dot {
      fill: white;
      stroke: black;
      stroke-width: 2px;
    }
    .annotate {
      line {
        fill: turquoise;
        stroke: turquoise;
      }

      circle:hover {
        cursor: move;
      }

      circle.background-circle {
        fill: transparent;
        stroke: none;

        &:hover {
          cursor: default;
        }
      }

      circle.inner-dot {
        fill: white;
        stroke: black;
      }

      circle.outer-dot {
        fill: transparent;
      }

      circle.ring {
        stroke: turquoise;
        fill: transparent;
        stroke-dasharray: 4 2;
        &.selected {
          stroke: rgb(11, 234, 11);
        }
      }

      .react-icon path {
        fill: transparent;
        stroke: rgb(11, 234, 11);
      }
    }
  }

  #loading-canvas {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #454545;
    animation: fadein linear 100ms;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
