#edit-case-form {

    h1,
    h3 {
        text-align: center;
    }

    h1 {
        font-size: 1.7rem;
        font-weight: 500;
    }

    h3 {
        font-size: 0.9rem;
        font-weight: 400;
    }

    .form-row {
        width: 100%;
        display: flex;
        column-gap: 1.4rem;
        box-sizing: border-box;
        justify-content: space-between;
    }

    .field-divider {
        width: 100%;
        display: flex;
    }
}